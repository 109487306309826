<template>
  <div class="w-100 h100 flex-1 relative">
    <div class="content">
      <el-tabs
        type="border-card"
        class="tabs"
      >
        <el-tab-pane :label="levelName"></el-tab-pane>
        <el-form
          :inline="true"
          :model="formInline[level]"
          class="demo-form-inline"
          size="mini"
        >
          <span v-if="level == 'campaign'">
            <el-form-item>
              <el-select
                v-model="formInline[level].platform"
                placeholder="选择平台"
                filterable
              >
                <el-option
                  :label="item"
                  :value="item"
                  v-for="item in initData.platformList"
                  :key="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="formInline[level].platform == 'snapchat'">
              <el-select
                v-model="formInline[level].adAccountName"
                filterable
                clearable
                remote
                reserve-keyword
                placeholder="请输入账户ID或账户名称"
                :remote-method="getSnapAccountList"
                :loading="accountSelectLoading"
              >
                <el-option
                  v-for="item in snapAccountList"
                  :key="item.adAccountId"
                  :label="item.adAccountName"
                  :value="item.adAccountName"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-else>
              <el-input
                v-model="formInline[level].adAccountName"
                placeholder="请输入账户ID或账户名称"
                @blur="trim('adAccountName')"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="formInline[level].adEntityName"
                placeholder="请输入系列ID或系列名称"
                @blur="trim('adEntityName')"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="formInline[level].currency"
                placeholder="币种"
                clearable
                filterable
              >
                <el-option
                  :label="item.title"
                  :value="item.code"
                  v-for="item in initData.currencyList"
                  :key="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
          </span>

          <el-form-item>
            <el-select
              v-model="formInline[level].labels"
              placeholder="标签"
              clearable
              filterable
              multiple
            >
              <el-option
                :label="item.name"
                :value="item.labelId"
                v-for="item in labels"
                :key="item.labelId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              placeholder="有无定时"
              clearable
              v-model="formInline[level].hasSchedule"
            >
              <el-option
                v-for="regular in hasScheduleList"
                :label="regular.label"
                :value="regular.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="formInline[level].campaignStatus"
              placeholder="状态"
              filterable
              clearable
              v-if="level == 'campaign'"
            >
              <el-option
                :label="item.label"
                :value="item.value"
                v-for="item in statusList"
                :key="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="formInline[level].originalStatus"
              placeholder="状态"
              filterable
              clearable
              v-if="level != 'campaign'"
            >
              <el-option
                :label="item.value"
                :value="item.code"
                v-for="item in initData.statusMap[formInline.campaign.platform]"
                :key="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="level == 'campaign'">
            <el-cascader
              v-model="formInline[level].ruleFilterList"
              placeholder="自动规则"
              :options="autoRuleList"
              clearable
              size="mini"
              :props="{
                expandTrigger: 'hover',
              }"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="dateRange[level]"
              type="daterange"
              :picker-options="adPickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="search"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
        <el-page-header
          style="margin-bottom: 10px"
          @back="goBack"
          v-if="this.level !== 'campaign'"
          :title="this.level == 'adset' ? '广告系列' : '广告组'"
          :content="this.level == 'adset' ? adCampaignMsg.campaignName : adMsg.adGroupName"
        ></el-page-header>
        <keep-alive exclude="adAds">
          <components
            is="ad-campaign"
            :level="level"
            @setLevelMsg="setLevelMsg"
            :adMsg="adMsg"
            :adCampaignMsg="adCampaignMsg"
            :params="params"
            ref="adsList"
            :key="level"
            @getTotalData="getTotalData"
            :totalLoading="totalLoading"
            :totalData="totalData"
          ></components>
        </keep-alive>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import adCampaign from './components/campaign.vue';
import globalData from './components/data.js';
import { getOptionData, queryConfigLabel, dailyStatistics, searchAccount } from '@/api/multiPlatform.js';
import { adPickerOptions, fnCSTToYmd } from '@/utils/utils';
import { autoRuleList } from './index.js';

export default {
  components: {
    adCampaign,
  },
  mixins: [globalData],
  data() {
    const platform = this.$route.query.platform || localStorage.getItem('multiPlatform_platform_select') || 'facebook';
    const adEntityName = this.$route.query.campaignId || '';
    return {
      level: 'campaign',
      adMsg: null,
      adCampaignMsg: null,
      initData: { statusMap: {} },
      formInline: {
        campaign: {
          platform,
          adEntityName,
        },
        adset: {},
        ad: {},
      },
      dateRange: {
        campaign: [new Date(), new Date()],
        adset: [new Date(), new Date()],
        ad: [new Date(), new Date()],
      },

      adPickerOptions: adPickerOptions,
      statusList: [
        { label: '开启', value: 'ACTIVE' },
        { label: '暂停', value: 'PAUSED' },
      ],
      labels: [],
      hasScheduleList: [
        {
          label: '有定时',
          value: '1',
        },
        {
          label: '全部',
          value: '0',
        },
      ],
      totalData: {},
      totalLoading: false,
      autoRuleList,
      accountSelectLoading: false,
      snapAccountList: [],
    };
  },
  watch: {
    level(newval, oldval) {},
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
    userId() {
      return this.$store.state.user.userInfo.data.id;
    },
    levelName() {
      return this.level == 'campaign' ? '广告系列' : this.level == 'adset' ? '广告组' : '广告';
    },
    params() {
      let param = { startDate: fnCSTToYmd(new Date(), { tag: true }), endDate: fnCSTToYmd(new Date(), { tag: true }) };
      const dateRange = this.dateRange[this.level];
      if (dateRange.length) {
        param.startDate = fnCSTToYmd(dateRange[0], { tag: true });
        param.endDate = fnCSTToYmd(dateRange[1], { tag: true });
      }
      for (let key in this.formInline[this.level]) {
        this.formInline[this.level][key] = this.formInline[this.level][key] || null;
      }

      return { ...this.formInline[this.level], ...param };
    },
  },

  methods: {
    async getInitData() {
      await getOptionData().then((res) => {
        this.initData = res.data;
      });
    },
    queryConfigLabel() {
      queryConfigLabel().then((res) => {
        this.labels = res.data;
      });
    },

    setLevelMsg(msg) {
      this.level = msg.level;

      const platform = this.formInline.campaign.platform;
      if (msg.level == 'adset') {
        this.adCampaignMsg = { ...msg };
        this.$set(this.dateRange, 'adset', this.dateRange.campaign);
        this.$set(this.formInline, 'adset', { platform });
      }
      if (msg.level == 'ad') {
        this.adMsg = { ...msg };
        this.$set(this.dateRange, 'ad', this.dateRange.adset);
        this.$set(this.formInline, 'ad', { platform });
      }
    },
    goBack() {
      this.level = this.level == 'adset' ? 'campaign' : 'adset';
    },
    // 获取snap 账户列表
    getSnapAccountList(query) {
      if (query !== '') {
        this.accountSelectLoading = true;
        searchAccount({ platform: this.formInline[this.level].platform, keyWord: query }).then((res) => {
          this.accountSelectLoading = false;
          if (res.code == 0) {
            this.snapAccountList = res.data;
          }
        });
      } else {
        this.snapAccountList = [];
      }
    },
    search() {
      if (this.level === 'campaign') {
        const { platform, ruleFilterList } = this.formInline.campaign;
        this.$set(this.formInline.campaign, 'ruleFilter', _.compact(ruleFilterList).join('&'));
        localStorage.setItem('multiPlatform_platform_select', platform);
      }
      this.$nextTick(() => {
        this.$refs.adsList.reload();
      });
    },
    // 去空格
    trim(key) {
      let newValue = this.formInline[key].trim();
      this.$set(this.formInline, key, newValue);
    },
    getTotalData() {
      this.totalLoading = true;
      dailyStatistics()
        .then((res) => {
          this.totalData = res.data;
        })
        .finally(() => {
          this.totalLoading = false;
        });
    },
  },
  mounted() {
    this.getInitData();
    this.queryConfigLabel();
  },
};
</script>

<style lang="scss" scoped>
.loadMore {
  text-align: center;
  line-height: 28px;
  color: #999;
}
.relative {
  position: relative;
}
.content {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  .tabs {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;

    ::v-deep .el-tabs__content {
      flex: 1;
      display: flex;
      flex-flow: column nowrap;
    }
  }
}
</style>
<style>
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  background: #ededed;
}
</style>
