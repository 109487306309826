<template>
  <el-dialog
    title="充值"
    :visible.sync="dialogVisible"
    width="500px"
    @close="$emit('close')"
    :show-close="false"
    :close-on-click-modal="false"
  >
    <el-form
      :model="form"
      :rules="rules"
      label-position="top"
      class="recharge"
      ref="form"
    >
      <el-form-item
        label="账单简称："
        prop="id"
      >
        <el-select
          placeholder="选择代理账单"
          class="formItem"
          filterable
          v-model="form.id"
          :loading="loadingVlToken"
          :disabled="!account_id"
        >
          <el-option
            :label="query.hasConfigVlToken ? query.agency : query.agency + '（请配置vlToken）'"
            :value="query.id"
            v-for="query in queryAgency"
            :key="query.id"
            :disabled="!query.hasConfigVlToken"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="充值金额"
        prop="amount"
      >
        <el-input-number
          placeholder="请输入充值金额"
          v-model="form.amount"
          :min="0"
          controls-position="right"
          style="width: 100%; text-align: left"
        ></el-input-number>
      </el-form-item>
    </el-form>
    <div class="dp-flex justify-c align-item-c">
      <el-button
        type="primary"
        @click="checkForm"
        >确定</el-button
      >
      <el-button
        type="info"
        @click="$emit('close')"
        >取消</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { recharge, findAgencyByAdAccountId, queryAgency } from '@/api/common/account';
export default {
  props: {
    dialogVisible: Boolean,
    account_id: String,
  },
  data() {
    return {
      rules: {
        amount: [
          {
            required: true,
            message: '请填写充值金额',
            trigger: 'blur',
          },
        ],
        id: {
          required: true,
          message: '请选择账单简称',
        },
      },
      form: {
        amount: '',
        id: '',
      },
      loadingVlToken: false,
      queryAgencyList: [], // 全部账单简称
      queryAgency: [], // 账号对应的账单简称
    };
  },
  methods: {
    checkForm() {
      if (!this.account_id) {
        this.$alert('广告账户 必须');
        return;
      }
      if (!this.form.amount || this.form.amount / 1 > 5000) {
        alert('充值金额 必须 且 不能大于5000');
        return;
      }
      this.sure();
    },
    sure() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let data = {
            account_id: this.account_id,
            amount: this.form.amount,
            agencyId: this.form.id,
            platform: 'facebook',
          };
          recharge(data).then((res) => {
            if (res.code) {
              return;
            }
            this.$alert('充值成功（十分钟左右生效，请在系统中刷新查看）');
            this.$emit('close');
          });
        }
      });
    },
    // 获取账单简称列表
    getQueryAgencyList() {
      queryAgency().then((res) => {
        this.queryAgencyList = res.data;
      });
    },
    // 获取帐号对应的账单简称
    findAgencyByAdAccountId() {
      let params = {
        adAccountId: this.account_id,
      };
      this.loadingVlToken = true;
      findAgencyByAdAccountId(params)
        .then((res) => {
          if (res.data) {
            this.queryAgency = [{ ...res.data }];
            this.$nextTick(() => {
              this.openAccount.id = res.data.id;
            });
          } else {
            this.queryAgency = this.queryAgencyList;
          }
        })
        .finally(() => {
          this.loadingVlToken = false;
        });
    },
  },
  watch: {
    account_id(val) {
      if (val) {
        if (this.$refs.form) {
          this.$refs.form.resetFields();
        }
        this.findAgencyByAdAccountId();
      }
    },
  },
  created() {
    this.getQueryAgencyList();
  },
};
</script>

<style scoped lang="scss">
.recharge {
  ::v-deep .el-input-number .el-input__inner {
    text-align: left;
  }
}
</style>
