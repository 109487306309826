<template>
  <el-drawer
    title="自动上品"
    size="1400px"
    @close="closeDialog"
    :visible.sync="dialogVisibleData"
    :wrapperClosable="false"
    class="h830"
    @opened="openInit"
    :destroy-on-close="true"
  >
    <div class="tabs-wrap">
      <div
        :class="['tabs-item', activeTab == index ? 'active-tab-item' : '']"
        v-for="(item, index) in platformList"
        :key="index"
        @click="changeTabs(index)"
      >
        <div :class="['iconfont', 'tabs-icon', item.icon]"></div>
        <p class="tabs-title">{{ item.name }}</p>
        <div :class="activeTab == index ? 'active-triangle' : ''">
          <div class="active-rectangle">√</div>
        </div>
      </div>
    </div>
    <!-- facebook自动上品 -->
    <!-- <upload-zip
      v-if="activeTab == 0"
      ref="addFacebook"
      @uploadSuccess="uploadSuccess"
      @uploadLoading="uploadLoading"
    ></upload-zip> -->
    <div class="addCon">
      <add-fb
        v-if="activeTab == 0"
        @uploadSuccess="uploadSuccess"
        @uploadLoading="uploadLoading"
        ref="addFacebook"
        :id="id"
      ></add-fb>
      <!-- Snapchat自动上品 -->
      <addSnapchat
        v-if="activeTab == 1"
        ref="addSnapchat"
        @uploadSuccess="uploadSuccess"
        @uploadLoading="uploadLoading"
      >
      </addSnapchat>
    </div>
    <el-footer>
      <el-row
        type="flex"
        justify="end"
      >
        <el-button @click="closeDialog">关闭</el-button>

        <el-button
          type="primary"
          @click="uploadStart(false)"
          :loading="loading"
          plain
          >确认</el-button
        >
        <el-button
          type="primary"
          @click="uploadStart(true)"
          :loading="loading"
          v-if="!id"
          >确认并继续</el-button
        >
      </el-row>
    </el-footer>
  </el-drawer>
</template>

<script>
import uploadZip from './UploadZip.vue';
import addSnapchat from './addSnapchat.vue';
import addFb from './addFb.vue';
export default {
  components: {
    uploadZip,
    addSnapchat,
    addFb,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String | Number,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      activeTab: 0, //选中的tab
      platformList: [
        {
          name: 'Facebook',
          icon: 'icon-facebook',
        },
        // {
        //   name: 'Snapchat',
        //   icon: 'icon-snapchat',
        // },
      ],
      dialogVisibleData: false,
    };
  },
  watch: {
    dialogVisible(newV, oldV) {
      this.dialogVisibleData = newV;
    },
  },
  methods: {
    openInit() {
      if (this.activeTab == 0) {
        this.$refs.addFacebook.getCatchData();
      }
    },
    changeTabs(index) {
      this.activeTab = index;
    },
    closeDialog() {
      this.$emit('setShowDialog', false);
      this.$refs.addSnapchat ? this.$refs.addSnapchat.clear() : '';
      // this.$refs.addFacebook ? this.$refs.addFacebook.clear() :'';
      this.activeTab = 0;
      this.loading = false;
    },
    uploadSuccess() {
      this.$emit('setShowDialog');
      this.$emit('getList');
    },
    uploadStart(flag) {
      switch (this.activeTab) {
        case 0:
          this.$refs.addFacebook.creatAds(flag);
          break;
        case 1:
          this.$refs.addSnapchat.submitForm('ruleForm');
        default:
          break;
      }
    },
    uploadLoading(bool = false) {
      this.loading = bool;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url(../.././../assets/iconfont/iconfont.css);

.h830 ::v-deep {
  .el-dialog {
    height: 80vh;
    overflow-y: scroll;
  }
}
.addCon {
  height: calc(100% - 140px);
  overflow-y: scroll;
}
.tabs-wrap {
  display: flex;
  justify-content: left;
  margin-bottom: 10px;
  padding: 0 20px;
  .tabs-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    margin-right: 30px;
    width: 90px;
    height: 70px;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    color: #333333;
    position: relative;

    .active-rectangle {
      display: none;
    }

    .tabs-icon {
      font-size: 24px;
    }

    .tabs-title {
      margin-top: 5px;
      font-size: 12px;
    }

    .active-triangle {
      position: absolute;
      right: 0;
      bottom: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid #0000ff;
      border-top: 10px solid transparent;
      border-bottom: 10px solid #0000ff;

      .active-rectangle {
        display: block;
        color: #fff;
        font-size: 12px;
        transform: scale(0.9);
        // width: 7px;
        // height: 10px;
        // border: 1px solid #ffffff;
        position: absolute;
        left: 50%;
        top: -2px;
      }
    }
  }

  .active-tab-item {
    background-color: #ebf1ff;
    border: 1px solid #0000ff;
    color: #0000ff;
  }
}

.dialog-footer {
  position: absolute;
  right: 30px;
  bottom: 10px;
}
</style>
