import request from '../../utils/request';
const BASEURL = process.env.VUE_APP_NEW_LOGIN;
export function doLoginByTicketApi(ticket) {
  return request({
    url: `/sso/doLoginByTicket?ticket=${ticket}`,
    method: 'get',
    baseURL: BASEURL,
  });
}
export function getSsoAuthUrlApi(params) {
  return request({
    url: `/sso/getSsoAuthUrl`,
    method: 'get',
    params,
    baseURL: BASEURL,
    //   data,
    //   headers: {
    //     'Content-Type': 'application/form-data'
    //   }
  });
}
export function doSsoLogout() {
  return request({
    url: `/sso/logout`,
    method: 'get',
    baseURL: BASEURL,
  });
}
