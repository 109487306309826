<template>
  <el-container>
    <el-header>
      <el-input
        class="customInput"
        v-model="filterParams.keyword"
        placeholder="标题关键词检索"
        clearable
      ></el-input>
      <el-select
        v-model="filterParams.platform"
        placeholder="请选择平台"
        clearable
      >
        <el-option
          v-for="item in platformList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="filterParams.target_type"
        placeholder="请选择作用对象"
      >
        <el-option
          v-for="item in targetTypeList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :disabled="item.disabled"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="filterParams.status"
        placeholder="请选择状态"
        clearable
      >
        <el-option
          v-for="(item, index) in statusList"
          :key="index"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-date-picker
        v-model="pickerDate"
        type="datetimerange"
        start-placeholder="请选择"
        end-placeholder="规则创建时间"
        :default-time="['00:00:00', '23:59:59']"
        value-format="yyyy-MM-dd HH:mm:ss"
      >
      </el-date-picker>
      <el-button
        @click="handleFind"
        type="primary"
        >查询</el-button
      >
      <el-button @click="handleReset">重置</el-button>
    </el-header>

    <el-main>
      <el-button
        class="mb-20"
        type="primary"
        @click="handleOpenDraw"
        >创建自定义规则</el-button
      >
      <el-button
        class="mb-20"
        type="primary"
        @click="handelOpenPop"
        >根据模板创建</el-button
      >
      <el-table
        stripe
        height="calc(100vh - 250px)"
        :data="tableData"
        style="width: 100%"
        @row-click="handleRowClick"
        v-loading="tableLoading"
        fit
      >
        <el-table-column
          :prop="item.prop"
          :label="item.label"
          :width="item.width ? item.width : ''"
          :min-width="item.minWidth ? item.minWidth : ''"
          v-for="(item, index) in tableColumnData"
          :key="index"
          :fixed="index < 2"
        >
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              :content="String(scope.row[item.prop])"
              placement="top"
            >
              <div class="customCell">
                {{ scope.row[item.prop] }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          label="状态"
          width="80"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              active-value="开启"
              inactive-value="暂停"
              :disabled="isSwitchAble"
              @change="switchChange(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="90"
          header-align="center"
        >
          <template slot-scope="scope">
            <!-- <el-button
              @click="handleClick(scope.row, scope.$index)"
              type="text"
              size="small"
              :disabled="btnLoading[scope.$index]"
              >执行</el-button
            > -->
            <el-button
              type="text"
              size="small"
              @click="handleUpdateClick(scope.row)"
              >修改</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="handleDeleteBtn(scope.row)"
              >删除</el-button
            >
            <!-- <el-button
              type="text"
              size="small"
              @click="handleLogOpen(scope.row)"
              >执行日志</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <div class="dp-flex justify-end mt-10">
        <el-pagination
          :hide-on-single-page="false"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="paging.total_count"
          :page-count="paging.total_pages"
          :current-page="filterParams.page_num"
          :page-size="filterParams.page_size"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          @current-change="changePage"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </div>
    </el-main>
    <addAutomaticRulesDraw
      ref="addAutomaticRulesDraw"
      :ruleChangeForm="ruleForm"
      @getList="getList"
    ></addAutomaticRulesDraw>
    <rulesTemplate
      ref="rulesTemplate"
      @getTemplateList="getTemplateList"
    ></rulesTemplate>
    <executionLogDraw ref="executionLogDraw"></executionLogDraw>
  </el-container>
</template>

<script>
import { throttle } from 'lodash';
import addAutomaticRulesDraw from '../components/addAutomaticRulesDraw.vue';
import executionLogDraw from '../components/executionLogDraw.vue';
import { rules, rulesDelete, rulesRun, rulesDetail, updateStatus } from '@/api/automaticRules';
import rulesTemplate from '../components/rulesTemplate.vue';
import commonData from '../components/commonData.js';
export default {
  components: {
    addAutomaticRulesDraw,
    executionLogDraw,
    rulesTemplate,
  },
  mixins: [commonData],
  data() {
    return {
      input: '',
      pickerDate: '',
      tableData: [],
      tableColumnData: [
        {
          prop: 'id',
          label: 'id',
          width: '60px',
        },
        {
          prop: 'title',
          label: '标题',
          minWidth: '200px',
        },
        {
          prop: 'platform',
          label: '平台',
        },
        {
          prop: 'target_type',
          label: '作用对象',
        },
        {
          prop: 'cron',
          label: '执行时间点',
          width: '295px',
        },
        // {
        //   prop: 'id2',
        //   label: '规则ID',
        //   width: '180px',
        // },
        {
          prop: 'create_time',
          label: '创建时间',
          width: '200px',
        },
      ],
      statusList: [
        { label: '开启', value: '开启' },
        { label: '暂停', value: '暂停' },
      ],
      filterParams: {
        keyword: '',
        platform: '',
        target_type: '',
        status: '',
        start_time: '',
        end_time: '',
        user_id: '',
        page_num: 1,
        page_size: 10,
      },
      paging: {},
      ruleForm: {},
      tableIndex: 0,
      btnLoading: [],
      tableLoading: false, //加载表格
      isSwitchAble: false,
    };
  },
  computed: {
    today() {
      // 返回格式化的当前日期字符串
      return this.formatDate(new Date(), 'today');
    },
    oneMonthAgo() {
      var date = new Date();
      date.setMonth(date.getMonth() - 1);
      // 返回格式化的一个月前的日期字符串
      return this.formatDate(date);
    },
  },
  watch: {},
  created() {
    // this.pickerDate[1] = this.today;
    // this.pickerDate[0] = this.oneMonthAgo;
    // this.pickerDate = ['','']
    this.getList();
  },
  methods: {
    getTemplateList(list) {
      this.ruleForm = list;
      this.handleOpenDraw();
    },
    //改变一页条数
    handleSizeChange(v) {
      this.filterParams.page_size = v;
      this.getList();
    },
    //改变页数
    changePage(v) {
      this.filterParams.page_num = v;
      this.getList();
    },
    //获取数据
    getList() {
      this.tableLoading = true;
      let paramsToSend = {};
      if (this.filterParams.platform) {
        paramsToSend.platform = this.filterParams.platform;
      }
      if (this.filterParams.status) {
        paramsToSend.status = this.filterParams.status;
      }
      if (this.filterParams.target_type) {
        paramsToSend.target_type = this.filterParams.target_type;
      }
      paramsToSend.keyword = this.filterParams.keyword;
      paramsToSend.start_time = this.pickerDate ? this.pickerDate[0] : '';
      paramsToSend.end_time = this.pickerDate ? this.pickerDate[1] : '';
      paramsToSend.user_id = this.filterParams.user_id;
      paramsToSend.page_number = this.filterParams.page_num;
      paramsToSend.page_size = this.filterParams.page_size;
      // this.filterParams.status = encodeURIComponent(this.filterParams.status);
      // console.log(paramsToSend, 'paramsToSend');
      // this.$showLoading();
      rules(paramsToSend)
        .then((res) => {
          if (res.code === 0) {
            let contents = res.data.contents ? res.data.contents : [];
            contents.forEach((element) => {
              this.targetTypeList.find((item) => {
                if (item.value === element.target_type) {
                  element.target_type = item.label;
                }
              });
              // if(element.cron){
              //   element.cron = element.cron
              //   .split(',')
              //   .map((item) => `${item}:00`)
              //   .join(',');
              // }
            });
            this.tableData = res.data.contents;
            // this.tableData.forEach(element=>{
            //   element.id = element.id.toSting()
            // })
            this.paging = res.data.paging;
          }
        })
        .finally(() => {
          this.tableLoading = false;
          this.$hideLoading();
        });
    },
    //改变开启暂停状态
    switchChange: throttle(function (row, isRun) {
      // console.log(row, isRun);
      // console.log(this.tableData[this.tableIndex]);
      this.isSwitchAble = true;
      const param = {
        rule_id: row.id,
        status: isRun ? (row.status === '暂停' ? '开启' : '暂停') : row.status,
      };

      this.updateStatus(param, isRun);
    }, 500),
    //调用改变状态接口
    updateStatus(param, isRun) {
      updateStatus(param)
        .then((res) => {
          this.isSwitchAble = false;
          if (res.code === 0) {
            // this.tableData[this.tableIndex].status === '暂停' ? '开启' : '暂停';
            // console.log(this.tableData)
            this.getList();
            if (isRun) {
              this.rulesRun(param.rule_id);
            }
            // this.$message({
            //   type: 'success',
            //   message: '执行成功!',
            // });
          }
        })
        .finally(() => {
          this.$hideLoading();
        });
    },
    //点击执行日志接口
    handleLogOpen(row) {
      this.$refs.executionLogDraw.openLogDraw();
      this.$refs.executionLogDraw.runList(row.id);
    },
    //点击修改按钮
    handleUpdateClick(row) {
      this.rulesDetail(row.id);
    },
    rulesDetail(id) {
      this.$showLoading();
      const param = { rule_id: id };
      rulesDetail(param)
        .then((res) => {
          if (res.code === 0) {
            this.ruleForm = res.data;
            this.$refs.addAutomaticRulesDraw.handleOpen();
          }
        })
        .finally(() => {
          this.$hideLoading();
        });
    },
    //点击执行按钮
    handleClick: throttle(function (row, index) {
      // console.log(row);
      if (row.status === '开启') {
        if (this.btnLoading.some((item) => item)) {
          this.$message('目前该规则正在执行，请在执行日志中查看执行进展');
          return;
        }
        this.$set(this.btnLoading, index, true);
        this.rulesRun(row.id);
        setTimeout(() => {
          this.$set(this.btnLoading, index, false);
        }, 5000);
      } else {
        this.$confirm('需要开启状态才可以执行，确认现在开启并执行吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.$set(this.btnLoading, index, true);
            this.switchChange(row, true);
            // this.rulesRun(row.id);
            setTimeout(() => {
              this.$set(this.btnLoading, index, false);
            }, 5000);
          })
          .catch(() => {});
      }
    }, 100),
    //点击删除按钮
    handleDeleteBtn(row) {
      this.$confirm('删除后该规则将不可恢复，确认继续删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          if (row.status === '开启') {
            this.$message({
              type: 'info',
              message: '目前该规则正在执行，请执行结束后再进行该操作',
            });
          } else {
            this.rulesDelete(row.id);
          }
        })
        .catch(() => {});
    },
    handleRowClick(row, column, event) {
      this.tableIndex = this.tableData.indexOf(row);
      // console.log('当前点击行的索引: ', this.tableData.indexOf(row));
    },
    //调用删除接口
    rulesDelete(id) {
      rulesDelete(id)
        .then((res) => {
          if (res.code === 0) {
            // this.tableData.splice(this.tableIndex, 1);
            this.getList();
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
          }
        })
        .finally(() => {
          this.$hideLoading();
        });
    },
    //调用执行接口
    rulesRun(id) {
      rulesRun(id)
        .then((res) => {
          if (res.code === 0) {
            this.$message({
              type: 'success',
              message: '执行成功!',
            });
          }
        })
        .finally(() => {
          this.$hideLoading();
        });
    },
    formatDate(date, isToday) {
      var month = '' + (date.getMonth() + 1),
        day = '' + date.getDate(),
        year = date.getFullYear(),
        hour = date.getHours(),
        minute = date.getMinutes(),
        second = date.getSeconds();

      if (month < 10) {
        month = `0${month}`;
      }
      if (day < 10) day = '0' + day;
      if (hour < 10) hour = '0' + hour;
      if (minute < 10) {
        minute = `0${minute}`;
      }
      if (second < 10) second = '0' + second;
      if (isToday === 'today') {
        return `${[year, month, day].join('-')} ${['23', '59', '59'].join(':')}`;
      } else {
        return `${[year, month, day].join('-')} ${['00', '00', '00'].join(':')}`;
      }
    },
    //查询按钮
    handleFind() {
      this.filterParams.page_num = 1;
      this.filterParams.page_size = 10;
      this.getList();
    },
    //重置按钮
    handleReset() {
      this.reset();
      this.getList();
    },
    //重置 初始化 清空
    reset() {
      this.pickerDate = null;
      this.filterParams.keyword = '';
      this.filterParams.platform = '';
      this.filterParams.target_type = '';
      this.filterParams.status = '';
      this.filterParams.page_num = 1;
      this.filterParams.page_size = 10;
    },
    //打开添加规则弹窗
    handleOpenDraw() {
      this.$refs.addAutomaticRulesDraw.handleOpen();
    },
    handelOpenPop() {
      this.$refs.rulesTemplate.handleOpen();
    },
  },
};
</script>

<style lang="scss">
.el-container {
  background: #ffffff;
  .el-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // padding: 20px 20px 30px 20px;
    .customInput {
      width: 200px;
    }
    .el-select {
      margin-left: 20px;
    }
    .el-range-editor {
      margin: 0 20px;
    }
  }
  .customCell {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 控制最多显示2行 */
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .el-table__body-wrapper {
    &::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
