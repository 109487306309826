<template>
  <div>
    <div class="login-container">
      <img
        class="login-transition"
        src="@/assets/image/loading3.gif"
        alt=""
      />
    </div>
  </div>
</template>
<script>
import { doLoginByTicketApi, getSsoAuthUrlApi } from '@/api/newLogin/index';
export default {
  beforeRouteEnter(to, from, next) {
    // 在路由进入前执行的逻辑
    console.log('beforeRouteEnter--to', to);
    console.log('beforeRouteEnter--from', from);
    next();
  },
  data() {
    return {};
  },
  methods: {
    goSsoAuthUrl() {
      const baseUrl = process.env.VUE_APP_DOMAIN;
      const params = {
        clientLoginUrl: `${baseUrl}/#/ad/loginBack`,
        back: `${baseUrl}/#/ad/adManagement`,
      };

      getSsoAuthUrlApi(params).then((res) => {
        if (res.code === 0) {
          console.log('goSsoAuthUrl', res.data);
          window.open(res.data, '_self');
        }
      });
    },
    doLoginByTicket(ticket, back) {
      doLoginByTicketApi(ticket).then((res) => {
        const saToken = res?.data || '';
        localStorage.setItem('saToken', saToken);
        window.close(); // 关闭当前标签页
      });
    },
    judgeTicket() {
      let ticket = this.$route.query?.ticket || '';
      let back = decodeURIComponent(this.$route.query?.back) || '';
      if (ticket) {
        this.doLoginByTicket(ticket, back);
      } else {
        this.goSsoAuthUrl();
      }
    },
  },
  created() {
    this.judgeTicket();
  },
};
</script>
<style lang="scss" scoped>
.login-container {
  width: 100%;
  height: 100vh; /* 容器高度为视口高度 */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.login-transition {
  width: 350px;
  height: 350px;
  object-fit: cover; /* 保持图片比例，填充容器 */
}
</style>
