import request from '../utils/request';
import store from '@/store';
import { env } from 'echarts/lib/export';
import qs from 'qs';
// const _token = store.getters.token;
// const _token = "1ff1cd3bb0350d4b2c07b76ebbc683b5"
// http://gateway.giikin.com/auto/api/rules?
const BASE_URL = process.env.VUE_APP_BASE_API;
const VUE_APP_OPERATE = process.env.VUE_APP_OPERATE;
const POST = (
  url,
  data,
  config = {
    baseURL: BASE_URL,
  },
  platform,
) => {
  return request({
    method: 'post',
    url: url,
    data,
    timeout: 60000,
    headers: {
      'Content-Type': 'application/json',
      platform: platform,
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
    ...config,
  });
};

const GET = (url, params, config = { baseURL: BASE_URL }) => {
  return request({
    method: 'get',
    url: url,
    params,
    ...config,
  });
};

export function campaignList(data) {
  return request({
    baseURL: process.env.VUE_APP_OPERATE,
    headers: {
      'Content-Type': 'application/json',
    },
    url: '/manage/ad/list',
    // url: '/giam/manage/ad/listGiam/632242', // tt有经营分析
    // url: '/giam/manage/ad/listGiam/632242', // ttshop 经营分析
    method: 'post',
    data: data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 获取广告创建常量
export function adConstsMap(params) {
  return request({
    baseURL: process.env.VUE_APP_BASE_API,
    url: '/manage/ads/adConstsMap',
    method: 'get',
    params: {
      ...params,
    },
  });
}
// 开启任务

export function spendJob(data) {
  return GET('/report/api/mobile/spend/job', data);
}
// 任务详情
export function jobDetail(data) {
  return GET('/job/jobDetail', data);
}
// 修改广告系列广告组状态
export function mutateStatus(data) {
  return request({
    url: '/manage/mutateStatus',
    baseURL: VUE_APP_OPERATE,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 修改广告系列广告组预算竞价
export function mutateBudget(data) {
  return request({
    url: '/manage/mutateBudget',
    baseURL: VUE_APP_OPERATE,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
export function batchMutateBudget(data) {
  return request({
    url: '/manage/batchMutateBudget',
    baseURL: VUE_APP_OPERATE,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 复制广告组到当前广告系列
export function copyAdset(data, platform) {
  return POST('/ads/copyAdset', data, process.env.VUE_APP_MOBILE_API, platform);
}
// 复制广告系列
// export function copyAdAsync(data) {
//   return POST('/manage/ad/copyAdAsync', data);
// }
// 设置定时开启
export function settingAdScheduleOpenTaskMobile(data) {
  return request({
    baseURL: process.env.VUE_APP_BASE_API,
    url: '/manage/ads/settingAdScheduleOpenTask',
    method: 'post',
    data: data,
    timeout: 0,
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 取消定时开启
export function delAdScheduleOpenTaskMobile(data) {
  return request({
    baseURL: VUE_APP_OPERATE,
    url: '/ads/delAdScheduleOpenTask',
    method: 'post',
    data: data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 设置定时预算
export function settingAdScheduleUpdateBudgetTaskMobile(data) {
  return request({
    baseURL: process.env.VUE_APP_BASE_API,
    url: '/manage/ads/settingAdScheduleUpdateBudgetTask',
    method: 'post',
    data: data,
    timeout: 0,
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 取消定时预算
export function delAdScheduleUpdateBudgetTaskMobile(data) {
  return request({
    baseURL: VUE_APP_OPERATE,
    url: '/ads/delAdScheduleUpdateBudgetTask',
    method: 'post',
    data: data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
//  取消定时出价
export function delUpdateBidTask(data) {
  return request({
    baseURL: VUE_APP_OPERATE,
    url: '/ads/delUpdateBidTask',
    method: 'post',
    data: data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 花费明细
export function spendHours(data) {
  return POST(`${process.env.VUE_APP_BASE_GATEWAY}/adreport/manage/ad/spendHours`, data);
}

// 定时改出价
export function settingAdScheduleUpdateBidTasks(data) {
  return POST('/manage/ads/settingAdScheduleUpdateBidTasks', data);
}

// 改出价 manage/mutateBatchBid
export function mutateBatchBid(data) {
  return POST('/manage/mutateBatchBid', data);
}

// 修改名称
export function mutateName(data) {
  return request({
    baseURL: VUE_APP_OPERATE,
    url: '/manage/mutateName',
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}

// 复制系列检测
export function copyAdCheck(data) {
  return request({
    url: '/manage/ad/copyAdCheck',
    baseURL: VUE_APP_OPERATE,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 校验规则
export function queryConfigDataCheck(params) {
  return request({
    baseURL: VUE_APP_OPERATE,
    url: '/manage/queryConfigDataCheck',
    params,
  });
}

// 复制
export function copyAdAsync(data) {
  return request({
    baseURL: VUE_APP_OPERATE,
    url: '/manage/ad/copyAdAsync',
    data,
    method: 'post',
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 日志
export function selectLogByPage(params) {
  return request({
    baseURL: VUE_APP_OPERATE,
    url: '/log/selectLogByPage',
    params,
  });
}

// 当日花费
export function dailySaleOrderTrend(data) {
  return request({
    baseURL: VUE_APP_OPERATE,
    url: '/manage/ad/dailySaleOrderTrend',
    data,
    method: 'post',
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}

export function settingAdScheduleOpenTask(data) {
  return request({
    baseURL: VUE_APP_OPERATE,
    url: '/ads/settingAdScheduleOpenTask',
    data,
    method: 'post',
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}

// 异常信息
export function selectExceptional(params) {
  return request({
    baseURL: VUE_APP_OPERATE,
    url: '/log/account/selectExceptional',
    params,
  });
}

// 批量改状态
export function mutateStatusMultiple(data) {
  return request({
    url: '/manage/mutateStatusMultiple',
    baseURL: VUE_APP_OPERATE,
    data,
    method: 'post',
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 枚举接口
export function getOptionData() {
  return request({
    url: '/manage/getOptionData',
    baseURL: VUE_APP_OPERATE,
  });
}
// 标签列表
export function queryConfigLabel() {
  return request({
    url: '/ads/queryConfigLabel',
    baseURL: VUE_APP_OPERATE,
  });
}

// 获取当日数据
export function dailyStatistics() {
  return request({
    url: '/manage/ad/dailyStatistics',
    baseURL: VUE_APP_OPERATE,
  });
}

// 定时改预算
export function settingAdScheduleUpdateBudgetTask(data) {
  return request({
    url: '/ads/settingAdScheduleUpdateBudgetTask',
    baseURL: VUE_APP_OPERATE,
    data,
    method: 'post',
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}

// 任务状态列表
export function getCopyList(data) {
  return request({
    url: '/manage/copy/getCopyList',
    baseURL: VUE_APP_OPERATE,
    data,
    method: 'post',
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}

// 重新复制
export function recopy(params) {
  return request({
    url: '/manage/copy/retry',
    baseURL: VUE_APP_OPERATE,
    params,
  });
}

// hasRead
export function hasRead(data) {
  return request({
    baseURL: VUE_APP_OPERATE,
    url: '/manage/copy/hasRead',
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}

// 获取本地订单
export function getLocalOrderStat(data) {
  return request({
    baseURL: VUE_APP_OPERATE,
    url: '/manage/getLocalOrderStat',
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}

export function getLevelColumns(params) {
  return request({
    baseURL: VUE_APP_OPERATE,
    // baseURL: 'http://192.168.1.93:11001/giam',
    url: '/column/getLevelColumns',
    params,
  });
}

// 获取设置的自定义列
export function myColumns(params) {
  return request({
    baseURL: VUE_APP_OPERATE,
    url: '/column/myColumns',
    params,
  });
}

//设置我使用的列组合
export function setUsedColumn(params) {
  return request({
    baseURL: VUE_APP_OPERATE,
    url: '/column/setUsedColumn',
    params,
  });
}

// 设置定制列
export function saveMyColumn(data) {
  return request({
    baseURL: VUE_APP_OPERATE,
    url: '/column/saveMyColumn',
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}

// 删除定制列
export function columnDelete(params) {
  return request({
    baseURL: VUE_APP_OPERATE,
    url: '/column/delete',
    params,
  });
}

// 单一平台数据统计 /giam/manage/ad/dailyStatisticsByPlatform
export function dailyStatisticsByPlatform(params) {
  return request({
    baseURL: VUE_APP_OPERATE,
    url: '/manage/ad/dailyStatisticsByPlatform',
    params,
  });
}

// 根据系列id查询账户fb
export function switchActByKeyword(data) {
  return request({
    baseURL: process.env.VUE_APP_GATEWAY_GADS2,
    url: '/delivery/ads/switchActByKeyword',
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}

// tt
export function queryAdsByRels(params) {
  return request({
    baseURL: process.env.VUE_APP_GATEWAY_TIKTOK,
    url: '/advertiser/queryAdsByRels',
    params,
  });
}

// 获取当前用户的所有可用账户
export function getAllAccount(params) {
  return request({
    baseURL: VUE_APP_OPERATE,
    url: '/manage/copyOption/getAllAccount',
    params,
  });
}

// 账户对应的像素
export function getAccountPixels(params) {
  return request({
    baseURL: VUE_APP_OPERATE,
    url: '/manage/copyOption/getAccountPixels',
    params,
  });
}

// 清缓存获取账户对应像素
export function refreshAccountPixels(params) {
  return request({
    baseURL: VUE_APP_OPERATE,
    url: '/manage/copyOption/refreshAccountPixels',
    params,
  });
}

// 根据推广连接获取站点和商品信息
export function promotionInfo(params) {
  return request({
    baseURL: VUE_APP_OPERATE,
    url: '/manage/copyOption/promotionInfo',
    params,
  });
}

// 根据关键词查询站点列表
export function searchSites(params) {
  return request({
    baseURL: VUE_APP_OPERATE,
    url: '/manage/copyOption/searchSites',
    params,
  });
}

// 谷歌近1小时订单通知
export function recentHourOrder(params) {
  console.log(process.env.VUE_APP_OPERATE, 'vvvvv', params);
  return request({
    baseURL: process.env.VUE_APP_OPERATE,
    url: `/saleCampaign/recentHourOrder`,
    params,
    transformRequest: (data) => {
      return data;
    },
  });
}
// 清缓存获取所有账户
export function cacheAndGetAccount(params) {
  return request({
    baseURL: VUE_APP_OPERATE,
    url: '/manage/copyOption/cacheAndGetAccount',
    params,
  });
}
// 获取账户snap 账户列表
export function searchAccount(data) {
  return POST('/manage/searchAccount', data, { baseURL: VUE_APP_OPERATE });
}
