import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import Layout from '@/layout';

// 业务助理("业务助理", 3),
// 主页维护("主页维护", 4),
// 优化主管("优化主管", 6),
// 优化专员("优化专员", 7),
// 客服主管("客服主管", 10),
// 客服专员("客服专员", 9),
const RolesEnum = {
  AGENT_DOCKING: 13, //代理对接
  AGENT_DOCKING_TEST: 95, //代理对接测试
  BUSINESS_ASSISTANT: 3, //业务助理
  HOMEPAGE_MAINTAIN: 4, //主页维护
  HOMEPAGE_MAINTAIN_TEST: 94, //主页维护测试
  OPTIMIZE_MANAGER: 6, //优化主管
  OPTIMIZE_MENBER: 7, //优化专员
  CUNSTOMER_MANAGER: 10, //客服主管
  CUNSTOMER_MENBER: 9, //客服专员
  PROGRAMMER: 32, //研发人员
  NUMMANAGE: 90, //个号管理测试
  NUMMANAGE2: 55, //个号管理正式
  ZZADMIN: 35, //郑州管理员
  TEAMADMIN: 56, //团队管理员
  MALLADMIN: 8, //商城维护
  MALLADMIN_TEST: 96, //商城维护测试
  OPENACCOUNT: 91, //开户人员测试
  OPENACCOUNT1: 57, //开户人员正式
  GKTOP: 110, //吉客拓客
  APPLY_APP_USER: 9999, //申请应用
};
let ids = [
  100, 32909, 888, 631068, 1342, 208, 34, 32648, 631103, 631030, 631031, 631035, 631036, 631038, 631040, 631041, 631042,
];
const constantRoutes = [
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true,
  },
  {
    path: '/ad',
    component: Layout,
    meta: {
      title: 'FB广告系统',
      // hideNavbarAccount: true,
      roles: [
        RolesEnum.OPTIMIZE_MENBER,
        RolesEnum.OPTIMIZE_MANAGER,
        RolesEnum.PROGRAMMER,
        RolesEnum.GKTOP,
        RolesEnum.APPLY_APP_USER,
      ],
    },
    children: [
      {
        path: '/ad/accountOverview',
        name: 'accountOverview',
        // redirect: '/asset/numManage/index',
        component: () => import('@/views/accountOverview/index.vue'),
        meta: {
          title: '账户概览',
          requireThirdLogin: true, //需要授权fb
          roles: [
            RolesEnum.OPTIMIZE_MENBER,
            RolesEnum.OPTIMIZE_MANAGER,
            RolesEnum.PROGRAMMER,
            RolesEnum.APPLY_APP_USER,
          ], //角色权限
          menuId: 5353,
        },
      },
      {
        path: '/ad/adManagement',
        name: 'adManagement',
        component: () => import('@/views/adManagement/index.vue'),
        meta: {
          title: '广告管理',
          requireThirdLogin: true,
          // hideNavbarAccount: true,
          roles: [
            RolesEnum.OPTIMIZE_MENBER,
            RolesEnum.OPTIMIZE_MANAGER,
            RolesEnum.PROGRAMMER,
            RolesEnum.GKTOP,
            RolesEnum.APPLY_APP_USER,
          ],
          menuId: 5354,
        },
      },
      {
        path: '/ad/createAd',
        name: 'CreateAd',
        component: () => import('@/views/adManagement/createAd/index.vue'),
        hidden: true,
        meta: {
          title: '新建广告',
          hideSideBar: true,
          requireThirdLogin: true,
          roles: [
            RolesEnum.OPTIMIZE_MENBER,
            RolesEnum.OPTIMIZE_MANAGER,
            RolesEnum.PROGRAMMER,
            RolesEnum.APPLY_APP_USER,
          ],
          // hideNavbarAccount: true
        },
      },
      {
        path: '/ad/checkAuth',
        name: 'checkAuth',
        component: () => import('@/views/adManagement/createFastAd/checkAuth.vue'),
        hidden: true,
        meta: {
          title: '账号检查',
          hideNavbarAccount: true,
          roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
        },
      },
      {
        path: '/ad/queryResult',
        name: 'queryResult',
        component: () => import('@/views/adManagement/createFastAd/queryResult.vue'),
        hidden: true,
        meta: {
          title: '选择结果',
          hideNavbarAccount: true,
          requireThirdLogin: true,
          roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
        },
      },
      {
        path: '/ad/createFastAd',
        name: 'createFastAd',
        component: () => import('@/views/adManagement/createFastAd/index.vue'),
        hidden: true,
        meta: {
          title: '新建商品广告',
          hideNavbarAccount: true,
          requireThirdLogin: true,
          roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
        },
      },
      //   {
      //     path: '/ad/multiAccountManageIndex',
      //     name: 'multiAccountManageIndex',
      //     component: () => import('@/views/adManagement/multiAccountManageIndex.vue'),
      //     meta: {
      //       title: '多账号广告管理',
      //       requireThirdLogin: true,
      //       roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
      //       menuId: 5355,
      //     },
      //   },
      // {
      //   path: '/ad/fbBatchAdManage',
      //   name: 'fbBatchAdManage',
      //   component: () => import('@/views/adManagement/fbBatchIndex.vue'),
      //   meta: {
      //     title: 'FB批量广告管理',
      //     hideNavbarAccount: true,
      //     // requireThirdLogin: true,
      //     roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER]
      //   }
      // },
      {
        path: '/ad/accountSelPage',
        name: 'accountSelPage',
        component: () => import('@/views/adManagement/components/accountSelPage.vue'),
        hidden: true,
        meta: {
          title: '账户选择',
          hideNavbarAccount: true,
          requireThirdLogin: true,
          roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
        },
      },
      // {
      //   path: '/ad/AutoRules',
      //   name: 'AutoRules',
      //   component: () => import('@/views/autoRules/index.vue'),
      //   meta: {
      //     title: '自动规则',
      //     requireThirdLogin: true,
      //     roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER]
      //   }
      // },
      {
        path: '/ad/setRule',
        name: 'setRule',
        component: () => import('@/views/autoRules/setRule/index.vue'),
        hidden: true,
        meta: {
          title: '设置规则',
          hideNavbarAccount: true,
          requireThirdLogin: true,
          roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
        },
      },
      {
        path: '/ad/createStrategy',
        name: 'createStrategy',
        component: () => import('@/views/autoRules/createStrategy/index.vue'),
        hidden: true,
        meta: {
          title: '创建策略',
          requireThirdLogin: true,
          roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
        },
      },
      {
        path: 'https://gk-ads.giikin.com/proprerty',
        // path: process.env.VUE_APP_ASSET_URL,
        name: 'AdAssetsAll',
        component: () => import('@/views/adAssets/index.vue'),
        meta: {
          title: '资产管理',
          requireThirdLogin: true,
          roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER, RolesEnum.GKTOP],
        },
      },
      {
        path: '/ad/AdAssets',
        name: 'AdAssets',
        component: () => import('@/views/adAssets/index.vue'),
        meta: {
          title: '广告资产',
          requireThirdLogin: true,
          roles: [
            RolesEnum.OPTIMIZE_MENBER,
            RolesEnum.OPTIMIZE_MANAGER,
            RolesEnum.PROGRAMMER,
            RolesEnum.GKTOP,
            RolesEnum.APPLY_APP_USER,
          ],
          menuId: 5356,
        },
      },
      //   {
      //     path: '/ad/thirdUserLabel',
      //     name: 'thirdUserLabel',
      //     component: () => import('@/views/thirdUserLabel/index.vue'),
      //     meta: {
      //       title: '个号标签',
      //       requireThirdLogin: true,
      //       roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
      //       menuId: 5357,
      //     },
      //   },
      {
        path: '/ad/preferenceSet',
        name: 'preferenceSet',
        component: () => import('@/views/adAssets/preferenceSet/index.vue'),
        hidden: true,
        meta: {
          title: '偏好设置',
          hideNavbarAccount: true,
          requireThirdLogin: true,
          roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
          menuId: 5812,
        },
      },
      //   {
      //     path: '/ad/pagePulish',
      //     name: 'pagePulish',
      //     component: () => import('@/views/pagePublish'),
      //     meta: {
      //       title: '主页发布',
      //       requireThirdLogin: true,
      //       // hideNavbarAccount: true,
      //       roles: [
      //         RolesEnum.AGENT_DOCKING,
      //         RolesEnum.AGENT_DOCKING_TEST,
      //         RolesEnum.BUSINESS_ASSISTANT,
      //         RolesEnum.HOMEPAGE_MAINTAIN,
      //         RolesEnum.HOMEPAGE_MAINTAIN_TEST,
      //         RolesEnum.OPTIMIZE_MANAGER,
      //         RolesEnum.OPTIMIZE_MENBER,
      //         RolesEnum.CUNSTOMER_MANAGER,
      //         RolesEnum.CUNSTOMER_MENBER,
      //         RolesEnum.PROGRAMMER,
      //       ],
      //       menuId: 5358,
      //     },
      //   },
      //   {
      //     path: '/ad/bmBind',
      //     name: 'bmBind',
      //     component: () => import('@/views/bmBind'),
      //     meta: {
      //       title: 'BM绑定',
      //       requireThirdLogin: true,
      //       roles: [
      //         RolesEnum.AGENT_DOCKING,
      //         RolesEnum.AGENT_DOCKING_TEST,
      //         RolesEnum.BUSINESS_ASSISTANT,
      //         RolesEnum.HOMEPAGE_MAINTAIN,
      //         RolesEnum.HOMEPAGE_MAINTAIN_TEST,
      //         RolesEnum.OPTIMIZE_MANAGER,
      //         RolesEnum.OPTIMIZE_MENBER,
      //         RolesEnum.CUNSTOMER_MANAGER,
      //         RolesEnum.CUNSTOMER_MENBER,
      //         RolesEnum.PROGRAMMER,
      //       ],
      //       menuId: 5359,
      //     },
      //   },
      {
        path: '/setting/mailList',
        name: 'mailList',
        component: () => import('@/views/mailList/index.vue'),
        meta: {
          title: '邮箱',
          hideNavbarAccount: true,
          requireThirdLogin: true,
          roles: [
            RolesEnum.HOMEPAGE_MAINTAIN,
            RolesEnum.HOMEPAGE_MAINTAIN_TEST,
            RolesEnum.PROGRAMMER,
            RolesEnum.OPTIMIZE_MENBER,
            RolesEnum.OPTIMIZE_MANAGER,
          ],
          menuId: 5360,
        },
      },
      //   {
      //     path: '/asset/mainPageManage',
      //     name: 'mainPageManage',
      //     component: () => import('@/views/mainPageManage/index.vue'),
      //     meta: {
      //       title: '主页管理',
      //       hideNavbarAccount: true,
      //       roles: [
      //         RolesEnum.AGENT_DOCKING,
      //         RolesEnum.AGENT_DOCKING_TEST,
      //         RolesEnum.BUSINESS_ASSISTANT,
      //         RolesEnum.HOMEPAGE_MAINTAIN,
      //         RolesEnum.HOMEPAGE_MAINTAIN_TEST,
      //         RolesEnum.OPTIMIZE_MANAGER,
      //         RolesEnum.OPTIMIZE_MENBER,
      //         RolesEnum.CUNSTOMER_MANAGER,
      //         RolesEnum.CUNSTOMER_MENBER,
      //         RolesEnum.PROGRAMMER,
      //       ],
      //       menuId: 5363,
      //     },
      //   },
      //   {
      //     path: '/openAccountList',
      //     name: 'openAccountList',
      //     component: () => import('@/views/openAccountList/index.vue'),
      //     meta: {
      //       title: '开户',
      //       requireThirdLogin: true,
      //       // hideNavbarAccount: true,
      //       roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
      //       menuId: 5805,
      //     },
      //   },
      //   {
      //     path: '/propertyList',
      //     name: 'propertyList',
      //     component: () => import('@/views/PropertyList/index.vue'),
      //     meta: {
      //       title: '资产更新',
      //       requireThirdLogin: true,
      //       // hideNavbarAccount: true,
      //       roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
      //       menuId: 5806,
      //     },
      //   },
      {
        path: '/addGoodsFb',
        name: 'addGoodsFb',
        component: () => import('@/views/AddGoodsFb/index.vue'),
        meta: {
          title: '自动上品',
          hideNavbarAccount: true,
          roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
          menuId: 5480,
        },
      },
      //   {
      //     path: '/multipleAds/log',
      //     name: 'log',
      //     component: () => import('@/views/multiPlatform/log/index.vue'),
      //     meta: {
      //       title: '操作日志',
      //       requireThirdLogin: true,
      //       hideNavbarAccount: true,
      //       roles: [
      //         RolesEnum.AGENT_DOCKING,
      //         RolesEnum.AGENT_DOCKING_TEST,
      //         RolesEnum.BUSINESS_ASSISTANT,
      //         RolesEnum.HOMEPAGE_MAINTAIN,
      //         RolesEnum.HOMEPAGE_MAINTAIN_TEST,
      //         RolesEnum.OPTIMIZE_MANAGER,
      //         RolesEnum.OPTIMIZE_MENBER,
      //         RolesEnum.CUNSTOMER_MANAGER,
      //         RolesEnum.CUNSTOMER_MENBER,
      //         RolesEnum.PROGRAMMER,
      //       ],
      //       menuId: 5808,
      //     },
      //   },
    ],
  },
  {
    path: '/google',
    component: Layout,
    meta: {
      title: 'Google广告系统',
      keepShow: true, //即使一个子路由也保持显示
      requireThirdLogin: true,
      // hideNavbarAccount: true,
      roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
      menuId: 5364,
    },
    children: [
      // {
      //   path: '/google/bulkCopy',
      //   name: 'bulkCopy',
      //   component: () => import('@/views/google/bulkCopy/index.vue'),
      //   meta: {
      //     title: 'google批量复制',
      //     hideNavbarAccount: true,
      //     // requireThirdLogin: true,
      //     roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER]
      //   }
      // },
      // {
      //   path: '/google/createGgAd',
      //   name: 'createGgAd',
      //   component: () => import('@/views/google/createGgAd/index.vue'),
      //   meta: {
      //     title: 'google广告创建',
      //     hideNavbarAccount: true,
      //     // requireThirdLogin: true,
      //     roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER]
      //   }
      // },
      {
        path: '/google/batchAdManage',
        name: 'batchAdManage',
        component: () => import('@/views/adManagement/batchIndex.vue'),
        meta: {
          title: 'google批量广告管理',
          hideNavbarAccount: true,
          // requireThirdLogin: true,
          roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
          menuId: 5365,
        },
      },
      // {
      //   path: '/google/accountWarning',
      //   name: 'accountWarning',
      //   component: () => import('@/views/google/accountWarning.vue'),
      //   meta: {
      //     title: '账户类型',
      //     hideNavbarAccount: true,
      //     // requireThirdLogin: true,
      //     roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
      //      menuId: 5366
      //   }
      // },
      {
        path: '/google/cashVoucher',
        name: 'cashVoucher',
        // hidden: true,
        component: () => import('@/views/google/cashVoucher'),
        meta: {
          title: '凭证管理',
          hideNavbarAccount: true,
          hidden: true,
          // requireThirdLogin: true,
          roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
          menuId: 5367,
        },
      },
      {
        path: '/google/success',
        name: 'getAuthSuccess',
        component: () => import('@/views/google/getAuthSuccess'),
        hidden: true,
        meta: {
          title: '授权成功',
          hideNavbarAccount: true,
          // requireThirdLogin: true,
          roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
          menuId: 5366,
        },
      },
      {
        path: '/google/accountManagement',
        name: 'accountManagement',
        // hidden: true,
        component: () => import('@/views/google/accountManagement'),
        meta: {
          title: '账户管理',
          hideNavbarAccount: true,
          // requireThirdLogin: true,
          roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
          menuId: 5368,
        },
      },
      {
        path: '/google/adManagement',
        name: 'googleAd',
        // hidden: true,
        component: () => import('@/views/google/adManagement'),
        meta: {
          title: '广告管理',
          hidden: true,
          hideNavbarAccount: true,
          // requireThirdLogin: true,
          roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
          menuId: 5369,
        },
      },
      {
        path: '/google/googleOrder',
        name: 'googleOrder',
        hidden: true,
        component: () => import('@/views/google/googleOrder'),
        meta: {
          title: '谷歌订单通知',
          hidden: true,
          hideNavbarAccount: true,
          // requireThirdLogin: true,
          roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
          menuId: 5369,
        },
      },
    ],
  },
  {
    path: 'https://tiktok.giikin.com',
    name: 'TikTokAd',
    // component: () => import('@/views/adManagement/index.vue'),
    meta: {
      title: 'TikTok广告系统',
      requireThirdLogin: true,
      // hideNavbarAccount: true,
      roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
      menuId: 5373,
    },
  },
  {
    path: '/intellect',
    component: Layout,
    meta: {
      title: '智能投放',
      keepShow: true, //即使一个子路由也保持显示
      // hideNavbarAccount: true,
      // requireThirdLogin: true,
      roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
    },
    children: [
      {
        path: 'https://gads3.giikin.com/fastDeliveryOnly',
        name: 'fastDeliveryOnly',
        // component: () => import('@/views/adManagement/index.vue'),
        meta: {
          title: '极速投放',
          requireThirdLogin: true,
          // hideNavbarAccount: true,
          roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
          menuId: 5073,
        },
      },
      {
        path: 'https://gads3.giikin.com/fastest',
        name: 'FastestAd',
        // component: () => import('@/views/adManagement/index.vue'),
        meta: {
          title: '一键投放',
          requireThirdLogin: true,
          // hideNavbarAccount: true,
          roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
          menuId: 5166,
        },
      },
      {
        path: 'https://gads3.giikin.com/property',
        name: 'PropertyAd',
        // component: () => import('@/views/adManagement/index.vue'),
        meta: {
          title: '资产管理',
          requireThirdLogin: true,
          // hideNavbarAccount: true,
          roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
          menuId: 5184,
        },
      },
    ],
  },
  {
    path: '/',
    component: Layout,
    redirect: 'ad/accountOverview',
  },

  {
    path: '/assetsReport',
    redirect: '/assetsReport/index',
    component: Layout,
    children: [
      {
        path: 'index',
        name: 'assetsReport',
        component: () => import('@/views/adAssets/components/assetsReport.vue'),
        hidden: true,
        meta: {
          title: '资产报告',
          hideNavbarAccount: true,
          requireThirdLogin: true,
          roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
        },
      },
    ],
  },

  {
    path: '/custom',
    component: Layout,
    meta: {
      title: '自定义受众',
      keepShow: true,
      roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
    },
    children: [
      {
        path: '/custom/customAudience',
        name: 'customAudience',
        component: () => import('@/views/customAudience/index.vue'),
        meta: {
          title: '自定义受众',
          requireThirdLogin: true,
          roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
          menuId: 5361,
        },
      },
      {
        path: '/custom/shareAudience',
        name: 'shareAudience',
        component: () => import('@/views/shareAudience/index.vue'),
        meta: {
          title: '共享受众',
          requireThirdLogin: true,
          roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
          menuId: 5362,
        },
      },
    ],
  },
  //   {
  //     path: '/setting',
  //     component: Layout,
  //     meta: {
  //       title: '主页发帖',
  //       hideNavbarAccount: true,
  //       roles: [
  //         RolesEnum.HOMEPAGE_MAINTAIN,
  //         RolesEnum.HOMEPAGE_MAINTAIN_TEST,
  //         RolesEnum.PROGRAMMER,
  //         RolesEnum.OPTIMIZE_MANAGER,
  //       ],
  //     },
  //     children: [
  //       {
  //         path: '/setting/operateGroupSetting',
  //         name: 'operateGroupSetting',
  //         component: () => import('@/views/operateGroupSetting/index.vue'),
  //         meta: {
  //           title: '运营优化组设置',
  //           hideNavbarAccount: true,
  //           roles: [RolesEnum.HOMEPAGE_MAINTAIN, RolesEnum.HOMEPAGE_MAINTAIN_TEST, RolesEnum.PROGRAMMER],
  //         },
  //       },
  //       {
  //         path: '/setting/pageMaintenance',
  //         name: 'pageMaintenance',
  //         component: () => import('@/views/pageMaintenance/index.vue'),
  //         meta: {
  //           title: '主页维护设置',
  //           hideNavbarAccount: true,
  //           roles: [RolesEnum.HOMEPAGE_MAINTAIN, RolesEnum.HOMEPAGE_MAINTAIN_TEST, RolesEnum.PROGRAMMER],
  //         },
  //       },
  //       {
  //         path: '/setting/pageMaintenanceResult',
  //         name: 'pageMaintenanceResult',
  //         component: () => import('@/views/pageMaintenanceResult/index.vue'),
  //         meta: {
  //           title: '主页维护结果提醒',
  //           hideNavbarAccount: true,
  //           roles: [RolesEnum.HOMEPAGE_MAINTAIN, RolesEnum.HOMEPAGE_MAINTAIN_TEST, RolesEnum.PROGRAMMER],
  //         },
  //       },
  //       {
  //         path: '/setting/mainPageMaintain',
  //         name: 'mainPageMaintain',
  //         component: () => import('@/views/assetManage/mainPageMaintain/index.vue'),
  //         meta: {
  //           title: '手动发帖',
  //           hideNavbarAccount: true,
  //           roles: [
  //             RolesEnum.AGENT_DOCKING,
  //             RolesEnum.AGENT_DOCKING_TEST,
  //             RolesEnum.BUSINESS_ASSISTANT,
  //             RolesEnum.HOMEPAGE_MAINTAIN,
  //             RolesEnum.HOMEPAGE_MAINTAIN_TEST,
  //             RolesEnum.OPTIMIZE_MANAGER,
  //             RolesEnum.OPTIMIZE_MENBER,
  //             RolesEnum.CUNSTOMER_MANAGER,
  //             RolesEnum.CUNSTOMER_MENBER,
  //             RolesEnum.PROGRAMMER,
  //           ],
  //         },
  //       },
  //     ],
  //   },
  {
    path: '/autoRulesResultM/index',
    component: () => import('@/views/autoRulesResultM'),
    // redirect: '/autoRulesResultM/index',
    meta: {
      title: '智能投放助手',
      requireThirdLogin: true,
      isMobile: true,
    },
    hidden: true,
  },
  // userInfo->配置页面
  {
    path: '/userInfo',
    redirect: '/userInfo/index',
    component: Layout,
    children: [
      {
        path: 'index',
        name: 'UserInfo',
        component: () => import('@/views/userInfo/index.vue'), //授权管理
        hidden: true,
        meta: {
          title: '授权管理',
          menuId: 5807,
        },
      },
    ],
  },
  {
    path: '/likesSet',
    redirect: '/likesSet/index',
    component: Layout,
    children: [
      {
        path: 'index',
        name: 'likesSet',
        component: () => import('@/views/adAssets/preferenceSet/userSetIndex.vue'), //授权管理
        hidden: true,
        meta: {
          title: '偏好设置',
          hideNavbarAccount: true,
          requireThirdLogin: true,
          menuId: 5804,
        },
      },
    ],
  },
  // 多平台
  {
    path: '/multiPlatform',
    redirect: '/multiPlatform/index',
    component: Layout,
    children: [
      {
        path: 'index',
        name: 'multiPlatform',
        component: () => import('@/views/multiPlatform/index.vue'), //授权管理
        meta: {
          title: '多平台广告(手机端)',
          hideNavbarAccount: true,
          roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
          menuId: 5371,
        },
      },
    ],
  },
  {
    path: '/snapchat',
    component: Layout,
    children: [
      {
        path: '/snapchat/create',
        component: () => import('@/views/snapchat/create/index.vue'),
        hidden: true,
        meta: {
          title: 'snapchat创建',
          hideSideBar: true,
          hideNavbarAccount: true,
          // requireThirdLogin: true,
          roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
          menuId: 5910,
        },
      },
    ],
  },
  // {
  //   path: '/multipleAds',
  //   component: Layout,
  //   meta: {
  //     title: '综合广告管理',
  //     keepShow: true, //即使一个子路由也保持显示
  //     // hideNavbarAccount: true,
  //     roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
  //     menuId: 5364,
  //   },
  //   children: [
  //     {
  //       path: 'https://gads.giikin.com/#/productReport',
  //       name: 'ProductReport',
  //       // component: () => import('@/views/adManagement/index.vue'),
  //       meta: {
  //         title: '广告管理',
  //         requireThirdLogin: true,
  //         // hideNavbarAccount: true,
  //         roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
  //         menuId: 5396,
  //       },
  //     },
  //     {
  //       path: '/multipleAds/log',
  //       name: 'log',
  //       component: () => import('@/views/multiPlatform/log/index.vue'),
  //       meta: {
  //         title: '操作日志',
  //         requireThirdLogin: true,
  //         hideNavbarAccount: true,
  //         roles: [
  //           RolesEnum.AGENT_DOCKING,
  //           RolesEnum.AGENT_DOCKING_TEST,
  //           RolesEnum.BUSINESS_ASSISTANT,
  //           RolesEnum.HOMEPAGE_MAINTAIN,
  //           RolesEnum.HOMEPAGE_MAINTAIN_TEST,
  //           RolesEnum.OPTIMIZE_MANAGER,
  //           RolesEnum.OPTIMIZE_MENBER,
  //           RolesEnum.CUNSTOMER_MANAGER,
  //           RolesEnum.CUNSTOMER_MENBER,
  //           RolesEnum.PROGRAMMER,
  //         ],
  //         menuId: 5808,
  //       },
  //     },
  //     {
  //       path: 'https://gads.giikin.com/#/accountManage',
  //       name: 'accountManage',
  //       meta: {
  //         title: '账户管理',
  //         requireThirdLogin: true,
  //         hideNavbarAccount: true,
  //         menuId: 5481,
  //       },
  //     },
  //     {
  //       path: 'https://gads.giikin.com/#/credentialManage',
  //       name: 'credentialManage',
  //       meta: {
  //         title: '凭证管理',
  //         requireThirdLogin: true,
  //         hideNavbarAccount: true,
  //         menuId: 5546,
  //       },
  //     },
  //     {
  //       path: 'https://gads.giikin.com/#/errorLog',
  //       name: 'errorLog',
  //       meta: {
  //         title: '错误日志',
  //         requireThirdLogin: true,
  //         hideNavbarAccount: true,
  //         menuId: 5547,
  //       },
  //     },
  //     {
  //       path: '/automatic',
  //       component: () => import('@/views/automaticRules/index.vue'),
  //       meta: {
  //         title: '自动规则',
  //         keepShow: false, //即使一个子路由也保持显示
  //         // hideInMenu: false, //是否显示在菜单
  //         roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
  //         menuId: 5432,
  //       },
  //       children: [
  //         {
  //           path: '/automaticRules',
  //           name: 'automaticRules',
  //           // hideInMenu: false, //是否显示在菜单
  //           component: () => import('@/views/automaticRules/automaticRulesManage/index.vue'),
  //           meta: {
  //             title: '管理自动规则',
  //             // requireThirdLogin: true, //是否检验个号，Google和自动规则没有个号也可以进去
  //             roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
  //             menuId: 5513,
  //           },
  //         },
  //         {
  //           path: '/automaticNote',
  //           name: 'automaticNote',
  //           // hideInMenu: false, //是否显示在菜单
  //           component: () => import('@/views/automaticRules/automaticNote/index.vue'),
  //           meta: {
  //             title: '通知列表',
  //             // requireThirdLogin: true, //是否检验个号，Google和自动规则没有个号也可以进去
  //             roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
  //             menuId: 5514,
  //           },
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    path: '/automatic',
    // component: () => import('@/views/automaticRules/index.vue'),
    component: Layout,
    meta: {
      title: '自动规则',
      keepShow: true, //即使一个子路由也保持显示
      //   hideInMenu: false, //是否显示在菜单
      roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
      menuId: 5432,
    },
    children: [
      {
        path: '/automaticRules',
        name: 'automaticRules',
        // hideInMenu: false, //是否显示在菜单
        component: () => import('@/views/automaticRules/automaticRulesManage/index.vue'),
        meta: {
          title: '管理自动规则',
          // requireThirdLogin: true, //是否检验个号，Google和自动规则没有个号也可以进去
          roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
          menuId: 5513,
        },
      },
      {
        path: '/automaticNote',
        name: 'automaticNote',
        // hideInMenu: false, //是否显示在菜单
        component: () => import('@/views/automaticRules/automaticNote/index.vue'),
        meta: {
          title: '通知列表',
          // requireThirdLogin: true, //是否检验个号，Google和自动规则没有个号也可以进去
          roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
          menuId: 5514,
        },
      },
    ],
  },
  //   {
  //     path: '/fastDelivery',
  //     name: 'fastDelivery',
  //     component: () => import('@/views/fastDelivery/index'),
  //     hidden: true,
  //     meta: {
  //       title: '极速投放',
  //       needNotProfile: true,
  //       // requireThirdLogin: true,
  //       // roles: [RolesEnum.OPTIMIZE_MENBER, RolesEnum.OPTIMIZE_MANAGER, RolesEnum.PROGRAMMER],
  //     },
  //   },
  {
    path: '/ad/loginTransition',
    component: () => import('@/views/loginTransition/backSidebar.vue'),
    hidden: true,
    children: [
      {
        path: '',
        name: 'loginTransition',
        component: () => import('@/views/loginTransition/index.vue'),
        meta: {
          title: '跳转型过渡页',
          menuId: 5971,
          hideNavbarAccount: true,
          hideInMenu: true,
          isWebView: false,
        },
      },
    ],
  },
  {
    path: '/ad/loginBack',
    component: () => import('@/views/loginTransition/transitonSidebar.vue'),
    hidden: true,
    children: [
      {
        path: '',
        name: 'loginBack',
        component: () => import('@/views/loginTransition/loginBack.vue'),
        meta: {
          title: '第二窗口型过渡页',
          menuId: 5971,
          hideNavbarAccount: true,
          hideInMenu: true,
          isWebView: false,
        },
      },
    ],
  },
  // 404页面必须放在最后
  {
    path: '*',
    redirect: '/404',
    hidden: true,
  },
];

const createRouter = () =>
  new Router({
    // mode: 'history', // 需要后端支持
    scrollBehavior: () => ({}),
    routes: constantRoutes,
  });

const router = createRouter();

export default router;
