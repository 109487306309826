<template>
  <div class="navbar">
    <div class="left-menu">
      <span
        class="cursor-pointer"
        @click="$router.push({ name: 'adManagement' })"
        >广告管理</span
      >
      <Account
        v-if="!hideNavbarAccount && !isAdmanagement"
        ref="selectAccount"
      />
      <multi-account v-if="isAdmanagement"></multi-account>
      <!-- <auto-rules v-if="!hideNavbarAccount"></auto-rules> -->
    </div>
    <div class="right-menu">
      <!-- 个号 -->
      <el-tooltip
        :content="`${
          selectNumObj.thirdUser.displayName ? selectNumObj.thirdUser.displayName : selectNumObj.thirdUser.thirdUserName
        }（ ${selectNumObj.thirdUser.thirdUserEmail}）`"
        effect="light"
        placement="left"
        v-if="selectNumObj"
      >
        <!--  -->
        <el-cascader
          v-show="!hideNavbarAccount && numList.length && !isAdmanagement"
          placeholder="请选择个号"
          :options="loginListLabel"
          v-model="num"
          filterable
          ref="cascader"
          size="mini"
          @focus="focusCascader"
          popper-class="third_user_cascader"
          :props="{ value: 'id', children: 'thirdLoginUsers', label: 'displayName' }"
          @change="selectedThirdUser"
        >
          <template slot-scope="{ node, data }">
            <span v-if="node.isLeaf">{{
              `${data.thirdUser.displayName ? data.thirdUser.displayName : data.thirdUser.thirdUserName}${
                data.thirdUser.thirdUserEmail ? '(' + data.thirdUser.thirdUserEmail + ')' : ''
              }`
            }}</span>
            <span v-else>{{ data.name }}</span>
          </template>
        </el-cascader>
        <!-- <el-select
          v-show="(!hideNavbarAccount) && (numList.length) && (!isAdmanagement)"
          size="mini"
          v-model="num"
          placeholder="请选择个号"
          @change="selectedThirdUser"
          filterable
          clearable
        >
          <el-option
            v-for="(item, index) in numList"
            :key="index"
            :label="`${
              item.thirdUser.displayName
                ? item.thirdUser.displayName
                : item.thirdUser.thirdUserName
            }${item.thirdUser.thirdUserEmail?'('+item.thirdUser.thirdUserEmail+')':''}`"
            :value="item.id"
          >
          </el-option>
        </el-select> -->
      </el-tooltip>
      <!-- 快速切换账号 -->
      <change-account v-if="!hideNavbarAccount && numList.length"></change-account>
      <!-- 任务列表 -->
      <el-tooltip content="点击查看任务详情">
        <div
          class="task-wrap pr"
          @click="openTaskManage"
        >
          <i class="el-icon-document task-i pr"></i>
          <div class="pa num">{{ taskNum }}</div>
        </div>
      </el-tooltip>
      <!-- 配置/登录 -->
      <el-tooltip content="点击可配置个号信息和退出登录">
        <el-dropdown
          class="right-menu-container"
          trigger="click"
          @command="handleCommand"
        >
          <div class="right-menu-wrapper">
            <div class="avatar">
              <img
                src="@/assets/icon/default_avatar.jpg"
                alt=""
              />
            </div>
          </div>
          <!-- {{loginListLabel}} -->
          <el-dropdown-menu
            slot="dropdown"
            class="user-dropdown"
          >
            <!-- <router-link to="/userInfo">
              <el-dropdown-item command="config">配置</el-dropdown-item>
            </router-link>
            <router-link to="/likesSet">
              <el-dropdown-item command="config">偏好设置</el-dropdown-item>
            </router-link> -->
            <el-dropdown-item command="quit">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-tooltip>
    </div>
    <!-- 任务列表抽屉 -->
    <el-drawer
      title="任务列表"
      :visible.sync="taskManageVisible"
      direction="ltr"
      size="50%"
      :modal-append-to-body="true"
    >
      <task-manage @post-task-num="getTaskNum"></task-manage>
    </el-drawer>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { myLoginList, setDefault, loginListGroup } from '@/api/common/user';
import { sessionJobsCount } from '@/api/taskManage';
import Account from '@/layout/components/Navbar/Account.vue';
import changeAccount from '@/layout/components/Navbar/changeAccount.vue';
import taskManage from '@/views/taskManage/index';
import autoRules from '@/layout/components/Navbar/autoRules';
import multiAccount from './Navbar/multiAccount.vue';
import Cookies from 'js-cookie';
import { doSsoLogout } from '@/api/newLogin/index';
export default {
  components: {
    Account: Account,
    taskManage,
    autoRules,
    changeAccount,
    multiAccount,
  },
  data() {
    return {
      taskNum: '',
      taskManageVisible: false,
      loginListLabel: [],
    };
  },
  computed: {
    ...mapState('num', ['selectedNum', 'numLists', 'selectNumLable']),
    ...mapGetters(['sidebar', 'hideNavbarAccount']),
    ...mapGetters('num', ['validNumLists', 'defNum']),
    numList() {
      let list = [...this.validNumLists];
      list.map((v) => {
        v.name = v.thirdUser.thirdUserName;
      });
      return list.sort(function (a, b) {
        return a.name.localeCompare(b.name, 'zh');
      });
    },
    selectNumObj() {
      return this.numLists.find((item) => item.id == this.selectedNum);
    },
    num: {
      get() {
        if (this.selectedNum) {
          let statusId = this.selectNumObj.status == 'VALID' ? 1 : 2;
          let selectNumLable =
            (this.selectNumLable ||
              sessionStorage.getItem('selectNumLable') / 1 ||
              localStorage.getItem('selectNumLable')) / 1 || statusId;
          return [selectNumLable, this.selectedNum];
        }
      },
      set() {
        this.$store.commit('num/SETSELECTEDNUM', {
          selectedNum: this.selectedNum,
        }); //防止vuex与v-model结合使用时提示属性可以获取但未设置
      },
    },
    isAdmanagement() {
      console.log(this.$route.path);
      return this.$route.path == '/ad/multiAccountManageIndex';
    },
  },
  methods: {
    taskNumPoll() {
      setTimeout(() => {
        this.sessionJobsCount();
      }, 60000);
    },
    sessionJobsCount() {
      sessionJobsCount().then((res) => {
        if (res.code == 0) {
          this.taskNum = res.data ? res.data : 0;
          this.taskNumPoll();
        }
      });
    },
    focusCascader() {
      this.$refs.cascader.inputValue = '';
    },
    getTaskNum(num) {
      this.taskNum = num;
    },
    openTaskManage() {
      this.taskManageVisible = true;
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    async logout() {
      await this.$store.dispatch('user/logout');
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
    handleCommand(c) {
      if (c == 'quit') {
        Cookies.remove('token');
        // window.location.href = '/api/logout';
        doSsoLogout().then(() => {
          // 当前页面打开URL页面
          localStorage.removeItem('saToken');
          Cookies.remove('satoken');
          location.reload();
        });
      }
    },
    myLoginList(platform) {
      this.$showLoading();
      let params = { platform };
      return myLoginList(params).then(async (res) => {
        this.$hideLoading();
        if (res.comment == 'ok') {
          // 个号列表存入-vuex
          await this.$store.dispatch('num/setNumLists', {
            numLists: res.data,
          });
        }
      });
    },
    loginListGroup() {
      let params = { platform: 'facebook' };
      return loginListGroup(params).then(async (res) => {
        this.$hideLoading();
        if (res.comment == 'ok') {
          let list = res.data.map((v) => {
            v.thirdLoginUsers = v.thirdLoginUsers || [];
            v.displayName = v.name;
            v.thirdLoginUsers.map((k) => {
              k.name = k.thirdUser.thirdUserName;
              k.displayName = k.thirdUser.displayName || k.thirdUser.thirdUserName;
              k.email = k.thirdUser.thirdUserEmail;
            });
            return { ...v };
          });
          this.loginListLabel = res.data;
          let arr = this.f_handleDataTree(res.data, '');
          console.log(arr, '个号信息');
          this.$store.commit('initData/setAllUseAdcounts', arr);
        }
      });
    },
    // 递归处理树
    f_handleDataTree(arr, val) {
      return arr.reduce((prev, cur, index, arr) => {
        if (cur.thirdLoginUsers) cur.thirdLoginUsers = this.f_handleDataTree(cur.thirdLoginUsers, cur.id);
        if (!val) {
          return [...prev, cur];
        } else return [...prev, { ...cur, pid: val }];
      }, []);
    },
    selectedThirdUser(val) {
      this.$store.commit('num/selectNumLable', val[0]);
      this.changeNum(val[1]);
    },
    async changeNum(val, account_id) {
      await this.$store.dispatch('num/setSelectedNum', {
        selectedNum: val,
      });
      this.$refs['selectAccount'].isSearch = false;
      this.$refs['selectAccount'].$refs['cusHeader'].searchIpt = '';

      localStorage.removeItem('activeAdAccount');
      this.$bus.$emit('trigger-getAdAccounts', account_id ? { obj: { id: account_id } } : {});
      // this.$showLoading();
      // setDefault({ id: val }).then(async res => {
      //   this.$hideLoading();
      //   if (res.comment == "ok") {
      //     await this.myLoginList("facebook");
      //     localStorage.removeItem("activeAdAccount");
      //     this.$bus.$emit("trigger-getAdAccounts", {});
      //   }
      // });
    },
  },
  created() {
    this.$bus.$on('trigger-myLoginList', (platform) => {
      this.myLoginList(platform);
      this.loginListGroup();
    });
    this.loginListGroup();
    let account_id = this.$route.query.account_id;
    let third_user_id = this.$route.query.third_user_id;
    if (account_id && third_user_id) {
      this.changeNum(third_user_id, account_id);
    }
    this.sessionJobsCount();
  },
};
</script>
<style>
.third_user_cascader .el-cascader-menu {
  min-width: auto !important;
}
</style>
<style lang="scss" scoped>
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  overflow: hidden;
  position: relative;
  color: #fff;
  background: #207ae6;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  padding: 0 0 0 24px;
  .left-menu {
    display: flex;
    align-items: center;
    height: 100%;
    line-height: 50px;
  }
  .right-menu {
    display: flex;
    align-items: center;
    height: 100%;
    line-height: 50px;
    .right-menu-container {
      display: flex;
      align-items: center;
      height: 100%;
      color: #fff;
      cursor: pointer;
      .right-menu-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0 12px;
        .avatar {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 28px;
          height: 28px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
      }
    }

    // task
    .task-wrap {
      margin-left: 12px;
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      .num {
        right: 0;
        top: -16px;
      }
      .task-i {
        color: #fff;
        font-size: 20px;
        top: 2px;
      }
    }
  }
}
</style>
